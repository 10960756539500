/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
// import styled from 'styled-components';
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Lead from '../components/Common/Lead';
import Services from '../components/Common/Services';

const Section = styled.section`
  .list-decimal {
    h3 {
      margin-top: 4rem;
    }
  }

  table {
    min-width: auto;
    max-width: 450px;

    th {
      h2 {
        font-size: 2.4rem;
        padding: 8px 0;
      }
    }

    tbody {
      td {
        padding: 0 12px;
        
        &:first-child {
          text-align: left;
        }
      }
    }
  }
`;

const GridList = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    h3 {
      font-size: 2.8rem;
    }

    > p {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  ul {
    background: #53204e;
    list-style: none;
    margin: 0 0 5rem;
    padding: 0;

    li {
      padding: 16px;

      &:nth-child(even) {
        background: #44193f;
      }
    }
  }

  .grid-list {
    &__content {
      align-items: center;
      display: grid;
      gap: 20px;
      grid-template-columns: 50% 1fr;
    }

    &__image {
      text-align: center;

      img {
        vertical-align: middle;
        width: 100%;
      }
    }

    &__desc {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      font-size: 2rem;

      p:not([class]) {
        margin: 0;
      }
    }

    &__year {
      font-weight: 900;
      color: #c8608b;
      font-size: 2rem;
      margin: 0;
    }

    &__name {
      font-weight: 600;
      font-size: 3rem;
      margin: 0 0 8px;
      order: -1;
    }
  }
`;

const siteMetadata = {
  title: 'Skup złotych monet - skup monet w najlepszych cenach | Olsztyn',
  description: 'Skup złotych monet w Olsztynie - gwarantujemy uczciwą wycenę monet na podstawie cen rynkowych. Skupujemy złote monety historyczne. Sprawdź naszą ofertę!',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-zlotych-monet/',
  locale: 'pl_PL',
};

const GoldCoins = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <h1>Skup złotych monet - Olsztyn</h1>

    <Lead
      maxWidth="850px"
      desc="Odkryj wartość swoich złotych monet! Skupujemy historyczne i kolekcjonerskie egzemplarze, oferując konkurencyjne ceny i bezpieczną transakcję."
    />

    <Section style={{ marginTop: '4rem' }}>
      <figure style={{ maxWidth: '1000px' }}>
        <GatsbyImage
          image={getImage(data.hero)}
          alt="złote monety obiegowe i kolekcjonerskie"
        />
        <figcaption>Amerykańskie złote monety</figcaption>
      </figure>

      {/* <table>
        <thead>
          <tr>
            <th colSpan="2"><h2>Skup złotych monet - aktualne ceny</h2></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>5 rubli carskich</td>
            <td>1450 zł</td>
          </tr>
          <tr>
            <td>10 rubli carskich</td>
            <td>2900 zł</td>
          </tr>
          <tr>
            <td>20 marek Prusy</td>
            <td>2500 zł</td>
          </tr>
          <tr>
            <td>20 franków</td>
            <td>2000 zł</td>
          </tr>
          <tr>
            <td>1 funt (suweren)</td>
            <td>2000 zł</td>
          </tr>
          <tr>
            <td>1 dukat Austrowęgry</td>
            <td>1195 zł</td>
          </tr>
        </tbody>
      </table> */}

      <div className="columns">
        <div className="col--6">
          <h2>Dlaczego warto nam zaufać?</h2>
          <ul>
            <li>oferujemy najlepsze ceny</li>
            <li>wycena monet odbywa się w oparciu o aktualne ceny rynkowe</li>
          </ul>

          <h2>Jakie monety skupujemy?</h2>

          <ul>
            <li><strong>polskie złote monety</strong> z okresu międzywojennego oraz te z czasów PRL</li>
            <li>współczesne złote monety emitowane przez NPB - 100 i 200 złotych</li>
            <li>złote monety historyczne z różnych okresów i regionów świata</li>
            <li>monety kolekcjonerskie z limitowanych edycji</li>
          </ul>

          <h2>Jak przebiega transakcja?</h2>

          <ol className="list-decimal">
            <li>
              <h3 style={{ marginTop: '10px' }}>Kontakt</h3>
              <p>Skontaktuj się z nami telefonicznie (<a href="tel:507506476">507&nbsp;506&nbsp;476</a>), wyślij do nas <a href="mailto:kontakt@monety.olsztyn.pl">maila</a> lub umów się z nami w Olsztynie.</p>
            </li>
            <li>
              <h3>Wycena</h3>
              <p>Przeprowadzimy szczegółową analizę Twoich monet, odpowiadając na wszystkie pytania.</p>
            </li>
            <li>
              <h3>Finalizacja</h3>
              <p>Po zaakceptowaniu oferty natychmiast realizujemy wypłatę.</p>
            </li>
          </ol>

          <h2>Chętnie skupujemy złote monety<br />historyczne i kolekcjonerskie</h2>

          <GridList>
            <li>
              <h3>Polska</h3>

              <p>II Rzeczypospolita</p>

              <ul>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.chrobry_10_zl_1925)}
                        alt="złota moneta - 10 złotych 1925"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Bolesław Chrobry</p>
                      <p className="grid-list__name">10 złotych 1925</p>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.chrobry_20_zl_1925)}
                        alt="złota moneta - 20 złotych 1925"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Bolesław Chrobry</p>
                      <p className="grid-list__name">20 złotych 1925</p>
                    </div>
                  </div>
                </li>
              </ul>

              <p>Złote monety kolekcjenorskie NBP</p>

              <ul>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.kolekcjonerskie_100)}
                        alt="100 złotych NBP"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">od 1997</p>
                      <p className="grid-list__name">100 złotych</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.kolekcjonerskie_200)}
                        alt="200 złotych NBP"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">od 1995</p>
                      <p className="grid-list__name">200 złotych</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li>
              <h3>Rosja</h3>

              <p>Car Mikołaj II</p>

              <ul>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.rosja_5_rubli)}
                        alt="złota moneta - 5 rubli Rosja"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">1899-1911</p>
                      <p className="grid-list__name">5 rubli</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.rosja_7_5_rubla)}
                        alt="złota moneta - 7,5 rubla Rosja"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">1897</p>
                      <p className="grid-list__name">7,5 rubla</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.rosja_10_rubli)}
                        alt="złota moneta - 10 rubli Rosja"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">1899-1910</p>
                      <p className="grid-list__name">10 rubli</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.rosja_15_rubli)}
                        alt="złota moneta - 15 rubli Rosja"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">1897</p>
                      <p className="grid-list__name">15 rubli</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li>
              <h3>USA</h3>

              <ul>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.indianin)}
                        alt="złota moneta - 5 dolarów USA"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Indianin</p>
                      <p className="grid-list__name">5 dolarów</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.liberty_5_dolarow)}
                        alt="złota moneta - 5 dolarów Liberty USA"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Liberty</p>
                      <p className="grid-list__name">5 dolarów</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.indianin_10_dolarow)}
                        alt="złota moneta - 10 dolarów Indianin USA"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Indianin</p>
                      <p className="grid-list__name">10 dolarów</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.liberty_10_dolarow)}
                        alt="złota moneta - 10 dolarów Liberty USA"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Liberty</p>
                      <p className="grid-list__name">10 dolarów</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.liberty_20_dolarow)}
                        alt="złota moneta - 20 dolarów Liberty USA"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">Liberty</p>
                      <p className="grid-list__name">20 dolarów</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="grid-list__content">
                    <div className="grid-list__image">
                      <GatsbyImage
                        image={getImage(data.st_gaudens_20_dolarow)}
                        alt="złota moneta - 20 dolarów St. Gaudens"
                      />
                    </div>
                    <div className="grid-list__desc">
                      <p className="grid-list__year">St. Gaudens</p>
                      <p className="grid-list__name">20 dolarów</p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </GridList>
        </div>

      </div>
    </Section>

    <Services />
  </>
);

export const query = graphql`
  query {
    hero: file(relativePath: {regex: "/zlote-monety-kolekcjonerskie.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 1000
        )
      }
    }
    chrobry_10_zl_1925: file(relativePath: {regex: "/10-zlotych-1925.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    chrobry_20_zl_1925: file(relativePath: {regex: "/20-zlotych-1925.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    kolekcjonerskie_100: file(relativePath: {regex: "/100-zlotych-kolekcjonerskie/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 336
        )
      }
    }
    kolekcjonerskie_200: file(relativePath: {regex: "/200-zlotych-kolekcjonerskie/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 313
        )
      }
    }
    rosja_5_rubli: file(relativePath: {regex: "/5-rubli-1898/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    rosja_7_5_rubla: file(relativePath: {regex: "/7-5-rubla-1897/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    rosja_10_rubli: file(relativePath: {regex: "/10-rubli-1901/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    rosja_15_rubli: file(relativePath: {regex: "/15-rubli-1897/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    indianin: file(relativePath: {regex: "/indianin-5-dolarow/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    indianin_10_dolarow: file(relativePath: {regex: "/indianin-10-dolarow/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    liberty_5_dolarow: file(relativePath: {regex: "/liberty-5-dolarow/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    liberty_10_dolarow: file(relativePath: {regex: "/liberty-10-dolarow/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    liberty_20_dolarow: file(relativePath: {regex: "/liberty-20-dolarow/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
    st_gaudens_20_dolarow: file(relativePath: {regex: "/st-gaudens-20-dolarow-1922/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 564
        )
      }
    }
  }
`;

export default GoldCoins;
